<template>
  <v-btn
    class="mt-4"
    small
    @click.native="onClick"
    v-bind="buttonProperties"
    :color="color"
    :to="route"
    :href="href"
    :target="linkTarget"
  >
    <v-icon v-if="icon" left>{{icon}}</v-icon>
    <span>{{ label|t }}</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    listKey: String,
    row: Object,
    name: String,
    index: Number,
    config: Object,
  },
  methods: {
    onClick() {
      if(typeof this.action.callback != 'function') return
      this.action.callback(window.eagleLodash.cloneDeep(this.row))
    },
  },
  computed: {
    action() {
      return this.config.action
    },
    color() {
      if(typeof this.action.color === 'function') {
        return this.action.color(this.row)
      }

      if(this.action.color) return this.action.color

      return 'primary'
    },
    icon() {
      if(typeof this.action.icon === 'function') {
        return this.action.icon(this.row)
      }

      if(this.action.icon) return this.action.icon

      return null
    },
    label() {
      if(typeof this.action.label === 'function') {
        return this.action.label(this.row)
      }

      return this.action.label || null
    },
    linkTarget() {
      if(this.action.linkTarget === undefined) return '_blank'
      return this.action.linkTarget || '_blank'
    },
    route() {
      if(typeof this.action.route === 'function') {
        return this.action.route(this.row)
      }

      return null
    },
    href() {
      if(typeof this.action.href === 'function') {
        return this.action.href(this.row)
      }

      return null
    },
    buttonProperties() {
      if(typeof this.config.buttonProperties != 'function') return null
      return this.config.buttonProperties(this.row)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
